@keyframes down_up_line {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-14px);
    }
}
.mainvisual {
    position: relative;
    display: flex;
    justify-content: flex-end;
    @include media-up(md) {
        margin-top: -30px;
    }
    @include media-down(md) {
        display: block;
    }
    &__img {
        width: 62.3%;
        @include media-down(md) {
            width: 100%;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    &__catch {
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        width: calc(100% - 3.5rem);
        max-width: 1060px;
        height: 100%;
        margin: auto;
        @include media-down(md) {
            position: static;
            margin-top: -52px;
        }
        &__title {
            max-width: 47%;
            @include media-down(md) {
                max-width: inherit;
                width: 100%;
            }
            img {
                max-width: 100%;
                height: auto;
                @include media-down(md) {
                    display: block;
                    margin: auto;
                }
            }
        }
    }
    &__scroll {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 96%;
        @include media-down(md) {
            display: none;
        }
        .scroll {
            margin-top: 9px;
            font-size: calc(12 / 16 * $font-size-base);
            font-family: 'Ysabeau SC', $font-family-sans-serif;
            font-weight: 800;
            color: $primary;
            letter-spacing: 0.08em;
        }
        .icon {
            overflow: hidden;
            height: 40px;
            img {
                animation: down_up_line 1.8s ease-in-out infinite;
            }
        }
    }
}
.block-news {
    margin-top: 120px;
    margin-bottom: 80px;
    padding: 0 map-get($spacers, 2);
    @include media-down(md) {
        margin-top: 35px;
        margin-bottom: 15px;
    }
    &__body {
        position: relative;
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
        padding: 15px 75px;
        border: solid 1px $primary;
        border-radius: 10px;
        @include media-down(lg) {
            padding: 30px map-get($spacers, 3);
        }
        @include media-down(sm) {
            padding: 30px map-get($spacers, 2);
        }
    }
    &__title {
        @include c-block-title();
        text-align: left;
        @include media-down(lg) {
            align-items: center;
            margin-bottom: 20px;
        }
        .eng {
            margin-bottom: 15px;
        }
        .text {
            display: flex;
            margin-left: 4px;
        }
        .svgicon {
            margin: 0 8px 0 0;
        }
        .ja {
            margin: 0;
        }
    }
    &__row {
        display: flex;
        align-items: flex-start;
        @include media-down(lg) {
            display: block;
        }
    }
    &__head {
        width: 200px;
        margin-top: 20px;
        @include media-down(lg) {
            width: 100%;
            margin-top: 0;
        }
    }
    &__list {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        &-wrap {
            width: calc(100% - 200px);
            @include media-down(lg) {
                width: 100%;
            }
        }
    }
    &__btnwrap {
        position: absolute;
        bottom: 55px;
        left: 65px;
        display: flex;
        justify-content: flex-end;
        @include media-down(lg) {
            position: static;
        }
        .btn {
            font-size: calc(14 / 16 * $font-size-base);
            color: $primary;
            letter-spacing: 0.18em;
            @include media-down(md) {
                padding-right: 0;
            }
            span {
                padding: 0.375rem;
                border-bottom: 2px solid $primary;
            }
            &:hover,
            &:focus {
                color: $secondary;
                span {
                    border-bottom-color: $secondary;
                }
                .icon {
                    color: $secondary;
                }
            }
        }
    }
}
.block-news-item {
    @include list-common__item();
    --item-new-color: $primary;
    position: relative;
    border-bottom: solid 1px #eaebeb;
    &:last-child {
        border-bottom: none;
    }
    &__link {
        display: block;
        padding: 1.4rem 0;
        color: #373737;
        transition: $transition-base;
        @include media-down(md) {
            padding: 0.8rem 35px 0.8rem 0;
        }
        &:hover,
        &:focus {
            color: $primary;
            text-decoration: none;
            .date,
            .comment {
                color: inherit;
            }
            .title.is-new:after {
                color: $tertiary;
            }
            .fa-arrow-right {
                right: 5px;
            }
        }
    }
    &__body {
        @include media-up(lg) {
            display: flex;
            // flex-direction: column;
        }
    }
    .title {
        flex-grow: 1;
        padding-right: 45px;
        font-size: calc(15 / 16 * $font-size-base);
        letter-spacing: 0.16em;
        color: inherit;
        @include media-up(lg) {
            font-size: $font-size-base;
        }
        @include media-down(md) {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
        }
        &.is-new:after {
            color: $tertiary;
        }
    }

    .date-cate {
        // margin-bottom: 0.5em;
        color: #666666;
        @include media-up(lg) {
            margin-right: 1em;
        }
    }
    .date {
        margin-bottom: 0;
        margin-right: 0.5em;
        font-family: 'Mulish', sans-serif;
        font-weight: $font-weight-bold;
        font-size: $font-size-xs;
        color: inherit;
        letter-spacing: 0.14em;
        @include media-down(md) {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .cate {
        vertical-align: middle !important;
        min-width: 70px;
        margin: 0;
        padding: 0;
        font-weight: $font-weight-bold;
        color: $primary;
        text-align: left;
        background: none;
        border: none;
        @include media-down(md) {
            max-width: calc(50vw - map-get($spacers, 3) - map-get($gutters, 3));
        }
    }
    .fa-arrow-right {
        transition: $transition-base;
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        right: 15px;
        color: $primary;
    }
}
.block-program {
    overflow: hidden;
    &__whats {
        padding: 0 map-get($spacers, 3);
        &__body {
            position: relative;
            width: 100%;
            max-width: 630px;
            margin-inline: auto;
            padding-top: 65px;
            padding-bottom: 220px;
            @include media-down(lg) {
                padding-top: 0;
                padding-bottom: 58px;
            }
        }
        &__bg01 {
            position: absolute;
            top: 0;
            left: 97%;
            z-index: -1;
            @include media-down(xl) {
                left: 95%;
            }
            @include media-down(lg) {
                position: static;
                display: flex;
                justify-content: center;
                margin-bottom: 27px;
            }
            img {
                max-width: 25vw;
                height: auto;
                @include media-down(lg) {
                    max-width: inherit;
                }
                @include media-down(sm) {
                    max-width: 90vw;
                    transform: translateX(2vw);
                }
            }
        }
        &__bg02 {
            position: absolute;
            z-index: -1;
            bottom: 75px;
            right: 97%;
            margin-bottom: 0;
            @include media-down(xl) {
                right: 91%;
            }
            @include media-down(lg) {
                position: static;
                display: flex;
                justify-content: center;
                margin-top: 13px;
            }
            img {
                max-width: 22vw;
                height: auto;
                @include media-down(lg) {
                    max-width: inherit;
                }
                @include media-down(sm) {
                    transform: translateX(4vw);
                }
            }
        }
        &__bg03 {
            position: absolute;
            z-index: -1;
            bottom: 125px;
            right: -160px;
            @include media-down(xl) {
                right: -100px;
            }
            @include media-down(lg) {
                display: none;
            }
            img {
                max-width: 37vw;
                height: auto;
            }
        }
    }
    &__title {
        @include c-block-title();
    }
    &__lead {
        margin-bottom: 35px;
        font-size: calc(26 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.12em;
        line-height: calc(51 / 26);
        text-align: center;
        @include media-down(md) {
            margin-bottom: 20px;
            font-size: calc(18 / 16 * $font-size-base);
        }
    }
    &__text {
        margin-bottom: 40px;
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.12em;
        line-height: calc(32 / 15);
        text-align: center;
        @include media-down(md) {
            margin-bottom: 30px;
            text-align: left;
            br {
                display: none;
            }
        }
    }
    &__btnwrap {
        display: flex;
        justify-content: center;
    }
    &__list {
        grid-template-columns: repeat(4, 1fr);
        @include media-down(lg) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-up(sm) {
            display: grid;
        }
        &-item {
            position: relative;
            text-decoration: none !important;
            @media (hover: hover) {
                &:hover {
                    .arrow {
                        background-color: darken($primary, 5%);
                        @include media-down(md) {
                            background-color: transparent;
                        }
                    }
                }
            }
            .titleWrap {
                position: absolute;
                top: -24px;
                left: 0;
                z-index: 1;
                display: flex;
                justify-content: center;
                width: 100%;
                @include media-down(sm) {
                    top: -19px;
                    width: 100vw;
                }
            }
            .title {
                display: flex;
                justify-content: center;
                width: 209px;
                height: 57px;
                margin: auto;
                padding-top: 15px;
                font-size: calc(18 / 16 * $font-size-base);
                font-weight: bold;
                letter-spacing: 0.12em;
                color: $primary;
                background: url(/assets/img/home/program-title@2x.png) no-repeat
                    top center / 100% auto;
                @include media-down(sm) {
                    width: 170px;
                    height: 45px;
                    padding-top: 11px;
                    font-size: calc(16 / 16 * $font-size-base);
                }
            }
            @media (hover: hover) {
                &:hover .imgWrap {
                    .img {
                        transform: scale(1.1);
                    }
                }
            }
            .imgWrap {
                overflow: hidden;
                aspect-ratio: 350 / 254;
                .img {
                    transition: $transition-base;
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            .body {
                position: relative;
                overflow: hidden;
                height: 100%;
            }
            .text-wrap {
                padding: 30px 45px;
                @include media-down(sm) {
                    padding: 20px 20px 30px;
                }
            }
            .text {
                margin-bottom: 0;
                font-size: calc(14 / 16 * $font-size-base);
                font-weight: bold;
                letter-spacing: 0.08em;
                line-height: calc(28 / 14);
            }
            .arrow {
                transition: $transition-base;
                position: absolute;
                right: -49px;
                bottom: -49px;
                display: block;
                width: 98px;
                height: 98px;
                background-color: $primary;
                border-radius: 50%;
                .fa-arrow-right {
                    transform: translate(22px, 18px);
                    color: #fff;
                }
            }
        }
    }
}
.block-pickup {
    padding: 85px map-get($spacers, 3) 110px;
    background-color: #c7e695;
    @include media-down(md) {
        padding: 60px map-get($spacers, 3) 70px;
    }
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    &__title {
        @include c-block-title();
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 33px;
        margin-bottom: 42px;
        @include media-down(md) {
            gap: 25px;
        }
        @include media-down(sm) {
            display: block;
            margin-bottom: 0;
        }
        &-item {
            overflow: hidden;
            position: relative;
            display: block;
            border-radius: 10px;
            @include media-down(sm) {
                margin-bottom: 7px;
                border-radius: 0;
            }
            @media (hover: hover) {
                &:hover {
                    text-decoration: none;
                    img {
                        opacity: 0.8;
                    }
                    .arrow {
                        background-color: darken($primary, 5%);
                        @include media-down(md) {
                            background-color: transparent;
                        }
                    }
                }
            }
            img {
                transition: $transition-base;
            }
            .arrow {
                transition: $transition-base;
                position: absolute;
                right: -49px;
                bottom: -49px;
                display: block;
                width: 98px;
                height: 98px;
                background-color: $primary;
                border-radius: 50%;
                @include media-down(md) {
                    top: 0;
                    bottom: 0;
                    right: 15px;
                    width: 14px;
                    height: 16px;
                    margin: auto;
                    background-color: transparent;
                }
                @include media-down(sm) {
                    display: none;
                }
                .fa-arrow-right {
                    transform: translate(22px, 18px);
                    color: #fff;
                    @include media-down(md) {
                        transform: none;
                    }
                }
            }
        }
    }
}
.block-voice {
    padding: 100px map-get($spacers, 3);
    @include media-down(md) {
        padding: 60px map-get($spacers, 3);
    }
    &__inner {
        width: 100%;
        max-width: 1180px;
        margin-inline: auto;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    &__title {
        @include c-block-title();
    }
    &__btnwrap {
        display: flex;
        justify-content: center;
    }
}
.block-voice__list {
    margin: 40px 0 50px;
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    @include media-down(md) {
        grid-template-columns: repeat(2, 1fr);
        gap: 25px 12px;
        margin: 25px 0 30px;
    }
}
.block-voice__item {
    overflow: hidden;
    a {
        transition: $transition-base;
        text-decoration: none !important;
    }
    &:hover {
        .subject {
            color: $primary;
        }
    }
    &:hover &-img {
        img {
            transform: scale(1.1);
        }
    }
    &-img {
        overflow: hidden;
        height: 220px;
        margin-bottom: 15px;
        border-radius: 10px;
        @include media-down(md) {
            height: auto;
            aspect-ratio: 1;
        }
        img {
            transition: $transition-base;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .cat {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 25px;
        margin-bottom: 5px;
        font-size: calc(12 / 16 * $font-size-base);
        font-weight: bold;
        color: #ff7a1f;
        background-color: #fff;
        border: solid 1px #ff7a1f;
        border-radius: 5px;
    }
    .subject {
        transition: $transition-base;
        font-size: calc(14 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.1em;
        line-height: calc(23 / 14);
        @include media-down(md) {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
    }
}
.block-loop {
    width: 100%;
    height: 348px;
    background-image: url(/assets/img/webp/home/gallery-img@2x.webp);
    background-repeat: repeat-x;
    background-size: auto 348px;
    animation: loop 30s linear infinite;
    @include media-down(md) {
        height: 217px;
        background-size: auto 217px;
    }
}
@keyframes loop {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -1550px 0;
    }
}
