.rwd002-fixNav {
  position: fixed;
  display: flex;
  border-radius: 10px 10px 0 0;
  clip-path: inset(0 round 10px 10px 0 0);
  transition: $transition-base;
  z-index: 2;

  @include media-up(lg) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: calc(50vh - calc(180px * 3 / 2) );
    top: calc(50svh - calc(180px * 3 / 2) );
    right: 0;
    clip-path: inset(0 round 10px 0 0 10px);
  }

  @include media-down(lg) {
    bottom: 0;
    align-items: stretch;
    width: 100%;
    background-color: $white;
    transition: all 0.2s ease-in-out;
    // transform: translateY(100%);
    z-index: 11;
  }

  &.is-active {
    @include media-down(lg) {
      opacity: 1;
      display: flex;
      transform: translateY(0);
    }
  }

  // &.hidden {
  //   transform: translateY(200px);
  //   opacity: 0;
  // }

  &:hover &__item {
    opacity: 0.5;
  }
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff7a1f;
    color: $white;
    text-align: center;
    font-weight: bold;
    font-size: calc(13 / 16 * $font-size-base);
    letter-spacing: 0.08em;
    transition: $transition-base;
    @include media-up(lg) {
      width: 55px;
      height: 140px;
      flex-direction: column;
      font-size: $font-size-sm;
      transform-origin: bottom;
    }

    @include media-down(lg) {
      flex-basis: calc(100% / 2);
      height: 45px;
      padding: .5rem;
      letter-spacing: 0.02em;
      text-indent: 0.02em;
    }

    span {
      @include media-up(lg) {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        text-align: left;
        line-height: calc(17 / 13);
        writing-mode: vertical-rl;
      }
    }

    .icon {
      fill: $white;
      @include media-up(lg) {
        letter-spacing: normal;
        margin-bottom: 0.2em;
      }
      @include media-down(lg) {
        margin-right: 0.5em;
      }
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: $white;
    }

    &.is-inquiry {
      .icon {
        font-size: calc(20 / 16 * $font-size-base);
      }
    }

    &.is-online {
      background-color: #02a9aa;
      .icon {
        font-size: calc(20 / 16 * $font-size-base);
        @include media-down(lg) {
          font-size: calc(18 / 16 * $font-size-base);
        }
      }
    }
  }

  & &__item:hover {
    opacity: 1;
  }
}