@mixin c-module-title() {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.75em;
    font-weight: normal;
    letter-spacing: 0.22em;
    text-indent: 0.22em;
    font-size: $h2-font-size;
    @include media-down(lg) {
        font-size: 1.875rem;
    }
    @include media-down(md) {
        font-size: $h4-font-size;
    }

    span {
        font-family: 'Roboto', $font-family-sans-serif;
        font-weight: $font-weight-normal;
    }

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: block;
        width: 82px;
        height: 1px;
        margin: auto;
        background: $primary;
    }
}

@mixin c-block-title($align: flex-start) {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    text-align: center;
    color: $primary;
    @include media-down(md) {
        margin-bottom: 20px;
    }
    .eng {
        font-size: calc(52 / 16 * $font-size-base);
        font-family: 'Ysabeau SC', $font-family-sans-serif;
        font-weight: 800;
        letter-spacing: 0.08em;
        line-height: 0.8;
        @if $align == 'center' {
            text-indent: 0.14em;
        }
        @include media-down(md) {
          font-size: calc(40 / 16 * $font-size-base);
        }
    }
    .ja {
        margin-top: 16px;
        font-size: calc(14 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.1em;
        @if $align == 'center' {
            text-indent: 0.2em;
        }
        @include media-down(md) {
          margin-top: 13px;
          font-size: calc(13 / 16 * $font-size-base);
        }
    }
    .svgicon {
        fill: $primary;
        margin: 13px auto 0;
        @include media-down(md) {
          margin-top: 12px;
        }
    }
}
