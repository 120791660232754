@keyframes up_down_line {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-8px);
    }
}

.rwd002-footerSocial {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    @include media-down(lg) {
        flex-direction: inherit;
        justify-content: center;
        margin-left: 0;
    }
    @include media-down(sm) {
        justify-content: space-between;
    }
    &__title {
        letter-spacing: 0.16em;
        margin-right: 1em;
        font-family: 'Lato', $font-family-sans-serif;
    }
    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 162px;
        height: 30px;
        font-size: calc(12 / 16 * $font-size-base);
        font-weight: bold;
        text-decoration: none !important;
        color: #fff !important;
        letter-spacing: 0.09em;
        border: solid 1px #fff;
        border-radius: 100px;
        @include media-down(lg) {
            margin: 0 5px;
        }
        @include media-down(sm) {
            width: calc((100% - 10px) / 2);
            height: 33px;
            margin: 0;
        }
        &:not(:last-child) {
            margin-bottom: 7px;
            @include media-down(lg) {
                margin-bottom: 0;
            }
        }
        span {
            display: inline-block;
            font-size: calc(14 / 16 * $font-size-base);
            margin-left: 5px;
        }
        i {
            font-size: calc(17 / 16 * $font-size-base);
        }
    }
}

.rwd002-pagetop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -31px;
    width: 63px;
    height: 63px;
    font-size: calc(13 / 16 * $font-size-base);
    font-weight: bold;
    color: $primary !important;
    text-decoration: none;
    letter-spacing: 0.13em;
    transform-origin: bottom;
    // border: 2px solid $primary;
    background-color: $white;
    border-radius: 50%;
    @include media-up(lg) {
        right: 50px;
    }
    @include media-down(lg) {
        right: calc(50% - 32px);
    }
    span {
        display: block;
    }
    .arrow {
        transform: rotate(-90deg);
        font-size: calc(11 / 16 * $font-size-base);
    }
    &:hover,
    &:focus {
        text-decoration: none;
        &:before {
            animation: up_down_line 0.9s infinite ease-in-out;
        }
    }
    &__wrap {
        width: 100%;
        z-index: 10;
        transition: $transition-base;
        @include media-up(lg) {
            position: fixed;
            position: sticky;
            bottom: 80px;
            margin: 0 auto;
            padding-left: map-get($spacers, 1);
            padding-right: map-get($spacers, 1);
        }
        @include media-down(lg) {
            position: relative;
        }
        &.hidden {
            transform: translateY(200px);
            opacity: 0;
            @include media-down(lg) {
                transform: none;
                opacity: 1;
            }
        }
    }
}

.rwd002-footer {
    position: relative;
    padding: map-get($spacers, 5) map-get($spacers, 3) map-get($spacers, 3);
    background: url(/assets/img/webp/footer_bg@2x.webp) no-repeat top center /
        cover;
    @include media-down(md) {
        padding: 55px map-get($spacers, 3) 65px;
        background-image: url(/assets/img/webp/footer_bg_sp@2x.webp);
    }
    &__inner {
        display: flex;
        align-items: flex-start;
        max-width: 960px;
        margin: 0 auto;
        @include media-down(lg) {
            flex-direction: column;
            align-items: stretch;
        }
    }

    &__info {
        @include media-up(lg) {
            flex-shrink: 0;
            width: 26.8%;
        }
        @include media-down(lg) {
            margin-bottom: map-get($spacers, 3);
        }
    }
    &__companyLogo {
        margin-top: 4px;
        margin-bottom: 17px;
        padding-left: 47px;
        @include media-down(lg) {
            margin-bottom: map-get($spacers, 2);
            padding-left: 0;
            text-align: center;
        }
    }
    &__address {
        font-size: calc(12 / 16 * $font-size-base);
        color: $gray-800;
        line-height: 2;
        letter-spacing: 0.26em;
        font-feature-settings: 'palt';
        @include media-down(lg) {
            text-align: center;
        }
        @include media-down(md) {
            margin-bottom: 24px;
            letter-spacing: 0.21em;
        }
        &:last-child {
            margin-bottom: 0;
        }
        .telwrap {
            display: flex;
            margin-left: 17px;
            @include media-down(lg) {
                justify-content: center;
                margin-left: 0;
            }
        }
        .zip {
            @include media-down(lg) {
                display: block;
            }
        }
        .tel {
        }
        &__tellik {
            position: relative;
            padding-left: 28px;
            font-size: calc(19 / 16 * $font-size-base);
            font-family: 'Rubik', sans-serif;
            font-weight: 400;
            color: #fff !important;
            letter-spacing: 0.12em;
            line-height: 1;
            text-decoration: none !important;
            &::before {
                content: '';
                left: 0;
                position: absolute;
                width: 17px;
                height: 17px;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:#00a656;}</style></defs><g id="_文字"><circle class="cls-1" cx="14" cy="14" r="14"/><path class="cls-2" d="m10.01,7.36l1.66,1.66c.24.24.22.65-.04.91l-.67.67c-.24.24-.28.6-.09.85,0,0,.23,1.54,2.03,3.34,1.84,1.84,3.29,1.97,3.29,1.97.25.19.61.15.85-.09l.67-.67c.26-.26.67-.28.91-.04l1.66,1.66c.24.24.22.65-.04.91,0,0-.1.1-1.09,1.09-1.15,1.15-5.37-1.05-7.72-3.41-2.36-2.36-4.56-6.57-3.41-7.72.99-.99,1.08-1.08,1.09-1.09.26-.26.67-.28.91-.04Z"/></g></svg>'
                    )
                    left center / 100% auto no-repeat;
            }
        }
        .holiday,
        .opentime {
            display: block;
        }
    }
    &__content {
        @include media-up(lg) {
            display: flex;
            flex-direction: column;
            width: 73.2%;
            min-height: 257px;
            padding-left: map-get($spacers, 6);
            border-left: solid 1px #e5e6e6;
        }
        @include media-down(lg) {
            width: 100%;
            padding-top: map-get($spacers, 3);
            border-top: solid 1px #e5e6e6;
        }
        &__head {
            display: flex;
            align-items: center;
            margin: 16px 0;
            font-size: calc(18 / 16 * $font-size-base);
            font-weight: bold;
            color: #fff;
            letter-spacing: 0.12em;
            line-height: calc(26 /18);
            @include media-down(lg) {
                margin: 0 0 map-get($spacers, 2);
            }
            @include media-down(md) {
                font-size: calc(14 / 16 * $font-size-base);
            }
            .svgicon {
                fill: #fff;
                margin-right: 5px;
            }
        }
        &__btnWrap {
            display: grid;
            grid-template-columns: repeat(2 ,1fr);
            gap: 25px;
            margin-bottom: 25px;
            @include media-down(sm) {
                display: block;
            }
        }
        &__btn {
            transition: $transition-base;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 74px;
            font-size: calc(18 / 16 * $font-size-base);
            font-weight: bold;
            color: #fff !important;
            text-decoration: none !important;
            letter-spacing: 0.2em;
            @include media-down(md) {
                height: 62px;
                font-size: calc(16 / 16 * $font-size-base);
            }
            &.inquiry {
                background-color: #ff7a1f;
                @include media-down(sm) {
                    margin-bottom: map-get($spacers, 2);
                }
                .icon {
                    font-size: calc(20 / 16 * $font-size-base);
                }
                &:hover {
                    background-color: darken(#ff7a1f, 10%);
                }
            }
            &.online {
                background-color: #02a9aa;
                &:hover {
                    background-color: darken(#02a9aa, 5%);
                }
            }
            .icon {
                margin-right: 5px;
            }
        }
    }

    &__bottom {
        @include media-up(sm) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 35px;
        }
        @include media-down(sm) {
            margin-top: 25px;
            text-align: center;
        }
        .slash {
            display: inline-block;
            margin: 0 10px;
            font-size: calc(10 / 16 * $font-size-base);
            font-weight: bold;
            color: rgba(255, 255, 255, 0.6);
            @include media-down(sm) {
                display: none;
            }
        }
    }
    &__copyright,
    &__login {
        display: block;
        letter-spacing: 0.15em;
        text-indent: 0.14em;
        color: rgba(255, 255, 255, 0.6);
        font-feature-settings: 'palt';
    }

    &__copyright {
        font-size: calc(10 / 16 * $font-size-base);
        font-weight: bold;
        @include media-down(sm) {
            letter-spacing: 0.1em;
        }
    }
    &__login {
        font-size: calc(11 / 16 * $font-size-base);
        @include media-down(sm) {
            margin-top: 6px;
        }
    }
}

.rwd002-footerNav {
    @include media-up(lg) {
        display: flex;
        flex-basis: 60%;
    }
    @include media-down(lg) {
        display: flex;
        flex-wrap: wrap;
    }
    @include media-down(md) {
        display: block;
    }
    &__list,
    &__sublist {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        font-size: $font-size-base * 0.9375;
        color: darken($white, 20%);
    }
    &__sublist {
        display: flex;
        font-size: $font-size-xs;
        margin-left: 15px;
        @include media-up(lg) {
            grid-column: 2;
            grid-row: 1;
        }
        @include media-down(md) {
            display: flex;
        }
    }
}

.rwd002-footerNav-item {
    margin-bottom: 12px;
    margin-right: 40px;
    @include media-down(sm) {
        margin-right: 19px;
    }
    &__link {
        position: relative;
        transition: $transition-base;
        display: inline-block;
        padding-left: 15px;
        font-size: calc(14 / 16 * $font-size-base);
        font-weight: bold;
        color: #fff !important;
        letter-spacing: 0.13em;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0.45em;
            left: 0;
            width: 8px;
            height: 8px;
            background-color: #fff;
            border-radius: 50%;
        }
    }
    &:nth-child(4) {
        @include media-up(lg) {
            margin-right: 0;
        }
    }
    &:last-of-type {
        margin-right: 0;
    }
}

.rwd002-footerNav-subitem {
    position: relative;
    &:not(:last-child) {
        &::after {
            content: '/';
            display: inline-block;
            margin: 0 10px;
            color: #fff;
        }
    }
    &__link {
        display: inline-block;
        font-size: calc(11 / 16 * $font-size-base);
        font-weight: $font-weight-bold;
        letter-spacing: 0.22em;
        transition: $transition-base;
        color: #fff !important;
        @include media-down(md) {
            padding: 0.2em 0;
        }
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.rwd002-footer-subnav {
    @include media-up(md) {
        display: none;
    }
    &__tgl {
        @extend .btn;

        &:focus {
            box-shadow: none;
        }

        @include media-up(md) {
            display: none;
        }
        @include media-down(md) {
            display: none !important;
            position: relative;
            flex-basis: 40px;
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: calc(50% - 5px);
                width: 10px;
                height: 1px;
                background-color: darken($white, 50%);
                transition: transform 0.2s ease-in-out;
            }

            &:after {
                transform: rotate(-90deg);
            }

            &.is-active {
                &:before {
                    transform: rotate(180deg);
                }
                &:after {
                    transform: rotate(0deg);
                }
            }
        }
    }
    &__list {
        list-style: none;
        @include media-down(md) {
            padding-left: 1.7em;
            padding-bottom: 1em;
        }
    }
}

.rwd002-footer-subnav-item {
    &__link {
        @include media-down(md) {
            display: block;
            padding: 0.2em 0;
        }
    }
}

.rwd002-footer-search {
    margin-top: 2em;
    @include media-down(lg) {
        width: 100%;
        margin-top: 1.5em;
        margin-bottom: 3em;
    }
    &__inner {
        position: relative;
        max-width: 320px;
        @include media-down(md) {
            margin-left: -0.8em;
            margin-right: -0.8em;
        }
    }
    &__input {
        @extend .form-control;
        padding-right: 2.2em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        background-color: tint($dark, 10%);
        transition: $transition-base;
        border-width: 0;
    }
    &__btn {
        @extend .btn;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(100% - 2.2em);
        width: 2.2em;
        padding: 0 0.5em;
        pointer-events: none;
        transition: $transition-base;
        > svg {
            width: 1em;
            height: 100%;
            fill: tint($dark, 30%);
        }
    }
    &__btn:focus,
    &__input:focus + &__btn {
        pointer-events: auto;
        right: 0;
    }
}

.footer-contact {
    padding: 115px map-get($spacers, 3) 120px;
    color: #fff;
    background-color: $secondary;
    @include media-down(lg) {
        padding: map-get($spacers, 5) map-get($spacers, 3);
    }
    &__inner {
        display: flex;
        width: 960px;
        max-width: 100%;
        margin: 0 auto;
        @include media-down(lg) {
            flex-direction: column;
            align-items: center;
        }
    }
    &__head {
        width: 320px;
        @include media-down(xl) {
            width: 300px;
        }
        @include media-down(lg) {
            margin-bottom: map-get($spacers, 2);
        }
    }
    &__body {
        display: flex;
        align-items: flex-end;
        width: calc(100% - 320px);
        @include media-down(xl) {
            justify-content: space-between;
            width: calc(100% - 300px);
        }
        @include media-down(lg) {
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
    }
    &__title {
        font-family: 'Mulish', $font-family-sans-serif;
        font-size: calc(43 / 16 * $font-size-base);
        letter-spacing: 0.08em;
        @include media-down(lg) {
            text-align: center;
        }
    }
    &__lead {
        margin-bottom: 0;
        font-size: calc(13 / 16 * $font-size-base);
        line-height: 2;
        letter-spacing: 0.2em;
        @include media-down(lg) {
            text-align: center;
        }
    }
    &__tel {
        @include media-down(lg) {
            margin-bottom: map-get($spacers, 3);
        }
        .link {
            position: relative;
            padding-left: 38px;
            font-family: 'League Spartan', $font-family-sans-serif;
            font-size: calc(30 / 16 * $font-size-base);
            letter-spacing: 0.13em;
            &::before {
                content: '';
                position: absolute;
                top: -0.1em;
                left: 0;
                width: 28px;
                height: 28px;
            }
        }
        p {
            margin: 6px 0 0 38px;
            font-size: calc(11 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            line-height: 1;
            letter-spacing: 0.21em;
            @include media-down(lg) {
                margin: 8px 0 0;
                text-align: center;
            }
        }
    }
    .btn-contact {
        margin-left: 45px;
        @include media-down(xl) {
            margin-left: 0;
        }
    }
}
